<!-- TODO: This page need be redsign and refactor -->
<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="onClickAway"
      class="options-menu dropdown-pane"
      :class="{ 'dropdown-pane--open': show }"
    >
      <woot-dropdown-menu>
        <li class="profile-holder">
          <ProfileImageAndData />
        </li>
        <div class="divider" />
        <li class="workspace-holder">
          <Workspace />
        </li>
        <div class="divider" />
        <availability-status />
        <receive-incoming-calls-status />

        <div class="sidebar-items-wrapper">
          <woot-dropdown-item v-if="globalConfig.hooryInboxToken">
            <button
              class="button small clear secondary"
              data-testid="menu-contact-support-button"
              @click="$emit('show-support-chat-window')"
            >
              <span class="icon-box">
                <fluent-icon
                  icon="chat-help"
                  size="20"
                  class="icon icon--font"
                  view-box="0 0 24 24"
                />
              </span>
              <span class="button__content">
                {{ $t('SIDEBAR_ITEMS.CONTACT_SUPPORT') }}
              </span>
            </button>
          </woot-dropdown-item>
          <woot-dropdown-item>
            <button
              class="button small clear secondary"
              data-testid="menu-keyboard-shortcuts-button"
              @click="handleKeyboardHelpClick"
            >
              <span class="icon-box">
                <fluent-icon
                  icon="keyboard-shortcuts"
                  size="20"
                  class="icon icon--font"
                  view-box="0 0 20 20"
                />
              </span>
              <span class="button__content">
                {{ $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
              </span>
            </button>
          </woot-dropdown-item>
          <woot-dropdown-item>
            <router-link
              v-slot="{ href, isActive, navigate }"
              :to="`/app/accounts/${accountId}/profile/settings`"
              custom
              data-testid="menu-redirect-profile-settings"
            >
              <a
                :href="href"
                class="button small clear secondary"
                :class="{ 'is-active': isActive }"
                @click="e => handleProfileSettingClick(e, navigate)"
              >
                <span class="icon-box">
                  <fluent-icon
                    icon="profile"
                    size="20"
                    class="icon icon--font"
                    view-box="0 0 20 20"
                  />
                </span>
                <span class="button__content">
                  {{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
                </span>
              </a>
            </router-link>
          </woot-dropdown-item>
          <woot-dropdown-item v-if="isAdmin">
            <router-link
              v-slot="{ href, isActive, navigate }"
              :to="`/app/accounts/${accountId}/settings/billing`"
              data-testid="menu-redirect-billing-settings"
              custom
            >
              <a
                :href="href"
                class="button small clear secondary"
                :class="{ 'is-active': isActive }"
                @click="e => handleProfileSettingClick(e, navigate)"
              >
                <span class="icon-box">
                  <fluent-icon
                    icon="billing"
                    size="20"
                    class="icon icon--font"
                    view-box="0 0 20 20"
                  />
                </span>
                <span class="button__content">
                  {{ $t('SIDEBAR_ITEMS.BILLING') }}
                </span>
              </a>
            </router-link>
          </woot-dropdown-item>
          <!-- TODO: temporary disabled  Report a Bug item     -->
          <!--           -->
          <!--          <woot-dropdown-item>-->
          <!--            <div data-testid="menu-report-bug-item">-->
          <!--              <span-->
          <!--                class="button small clear secondary"-->
          <!--                @click="e => handleCannyAction('bug')"-->
          <!--              >-->
          <!--                <span class="icon-box">-->
          <!--                  <fluent-icon-->
          <!--                    icon="bug"-->
          <!--                    size="20"-->
          <!--                    class="icon icon&#45;&#45;font"-->
          <!--                    view-box="0 0 20 20"-->
          <!--                  />-->
          <!--                </span>-->
          <!--                <span class="button__content">-->
          <!--                  {{ $t('SIDEBAR_ITEMS.REPORT_A_BUG') }}-->
          <!--                </span>-->
          <!--              </span>-->
          <!--            </div>-->
          <!--          </woot-dropdown-item>-->
          <woot-dropdown-item>
            <div data-testid="menu-feedback-item">
              <span
                class="button small clear secondary"
                @click="e => handleCannyAction('feedback')"
              >
                <span class="icon-box">
                  <fluent-icon
                    icon="feedback"
                    size="20"
                    class="icon icon--font"
                    view-box="0 0 20 20"
                    fill-rule="evenodd"
                  />
                </span>
                <span class="button__content">
                  {{ $t('SIDEBAR_ITEMS.SUBMIT_FEEDBACK') }}
                </span>
              </span>
            </div>
          </woot-dropdown-item>
          <woot-dropdown-item>
            <div data-testid="menu-feature-item">
              <span
                class="button small clear secondary"
                @click="e => handleCannyAction('feature')"
              >
                <span class="icon-box">
                  <fluent-icon
                    icon="feature"
                    size="20"
                    class="icon icon--font"
                    view-box="0 0 20 20"
                  />
                </span>
                <span class="button__content">
                  {{ $t('SIDEBAR_ITEMS.FEATURE_REQUEST') }}
                </span>
              </span>
            </div>
          </woot-dropdown-item>
          <div class="divider" />
          <woot-dropdown-item>
            <button
              data-testid="menu-logout-button"
              class="button small clear secondary"
              @click="logout"
            >
              <span class="icon-box">
                <fluent-icon
                  icon="logout"
                  size="20"
                  class="icon icon--font"
                  view-box="0 0 20 20"
                />
              </span>
              <span class="button__content">
                {{ $t('SIDEBAR_ITEMS.LOGOUT') }}
              </span>
            </button>
          </woot-dropdown-item>
        </div>
      </woot-dropdown-menu>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import Auth from '../../../api/auth';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import AvailabilityStatus from 'dashboard/components/layout/AvailabilityStatus';
import ReceiveIncomingCallsStatus from 'dashboard/components/layout/ReceiveIncomingCallsStatus';
import wootConstants from 'dashboard/constants/globals.js';
import ProfileImageAndData from '../ProfileImageAndData.vue';
import Workspace from '../Workspace.vue';
import isAdmin from '../../../mixins/isAdmin';

const { SERVICES_PATH } = wootConstants;

export default {
  components: {
    Workspace,
    ProfileImageAndData,
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatus,
    ReceiveIncomingCallsStatus,
  },
  mixins: [clickaway, isAdmin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { SERVICES_PATH };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
    showChangeAccountOption() {
      if (this.globalConfig.createNewAccountFromDashboard) {
        return true;
      }

      const { accounts = [] } = this.currentUser;
      return accounts.length > 1;
    },
  },
  methods: {
    handleProfileSettingClick(e, navigate) {
      this.$emit('close');
      navigate(e);
    },
    handleKeyboardHelpClick() {
      this.$emit('key-shortcut-modal');
      this.$emit('close');
    },
    logout() {
      Auth.logout();
    },
    onClickAway() {
      if (this.show) this.$emit('close');
    },
    async handleCannyAction(type) {
      let url = `/${SERVICES_PATH.WEB_SIDECAR}/v1/canny/sso`;
      let data = {
        name: this.currentUser.name,
        id: this.currentUser.id,
        email: this.currentUser.email,
        type: type,
      };
      await axios
        .post(url, data)
        .then(response => {
          if (response.status !== 200) {
            bus.$emit('newToastMessage', 'Error redirecting to canny!!');
          } else {
            // window.location.href = response.data.url
            window.open(response.data.url);
          }
        })
        .catch(() => {
          bus.$emit('newToastMessage', 'Error redirecting to canny!!');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.options-menu.dropdown-pane {
  left: var(--space-slab);
  bottom: var(--space-larger);
  min-width: var(--space-giga);
  top: unset;
  z-index: var(--z-index-higher);
}
.menu-icon {
  margin: 0.625rem 0.5rem 0.625rem 0;
}
.icon-box {
  margin: 0.625rem 0.5rem 0.625rem 0;
}
.divider {
  border-bottom: 1px solid var(--s-50);
  margin-bottom: var(--space-small);
  margin-top: var(--space-small);
}

.sidebar-items-wrapper {
  svg {
    position: relative;
    left: -3px;
  }
}
</style>
